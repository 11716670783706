import { useEffect } from "react"
import { BsFillPatchCheckFill } from "react-icons/bs"
import { VscError } from "react-icons/vsc"
import { ImSpinner9 } from "react-icons/im"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { RootState, AppDispatch } from "../../store"
import { idleValidateEmailStatus, validateEmail } from "./redux/validationSlice"
import "./validation-email-page.scss"
import { Button, notification } from "antd"
import { acceptShareData, idleAcceptShareDataStatus } from "../ShareData/redux/shareDataSlice"
import { useTranslation } from "react-i18next"


function ValidationEmail() {
  const { t } = useTranslation('validationEmail')
  const { token } = useParams()
  const dispatch:AppDispatch = useDispatch()
  const validateEmailStatus = useSelector((state:RootState) => state.validation.validateEmailStatus)


  useEffect(() => {
    if (token) {
      dispatch(validateEmail({ token }))
    }

    return () => {
      dispatch(idleValidateEmailStatus())
      dispatch(idleAcceptShareDataStatus())
    }
  }, [])


  return (
    <div className="validation_email_page">
      <div className="background-gradient"></div>
      <img className="logo" src="/images/logo.png"/>
      <h1>Secure & Access</h1>

      <div className="validation_email_result">
        {!token && <><VscError color="#ff4d4f"/>&nbsp;<span>{t('Invalid validation token')}</span></>}
        {token && (
          (validateEmailStatus === "idle" && <><ImSpinner9 className="icon-spin"/>&nbsp;<span>{t('Wait please.')}</span></>) || 
          (validateEmailStatus === "loading" && <><ImSpinner9 className="icon-spin"/>&nbsp;<span>{t('Wait please, we are validating your email.')}</span></>) ||
          (validateEmailStatus === "success" && <><BsFillPatchCheckFill color="#52c41a"/>&nbsp;<span>{t('Congratulations! Your email has been successfully validated.')}</span></>) ||
          (validateEmailStatus === "error" && <><VscError color="#ff4d4f"/>&nbsp;<span>{t('Error. We could not validate your email.')}</span></>)
        )}
      </div>

      <div>{t('Thank you. You can close this page.')}</div>
    </div>
  )
}

export default ValidationEmail
