import React, { useEffect } from 'react'
import Header from '../../components/Header/Header'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { Navigate, useParams } from 'react-router'
import { APPLICATIONS } from './apps'
import { Link } from 'react-router-dom'
import './application.scss'
import { Button, Form, notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  attemptGetApplicationData,
  attemptUpdateApplicationData,
  idleAttemptGetApplicationDataStatus,
  idleAttemptUpdateApplicationDataStatus,
} from './redux/applicationsSlice'
import { AppDispatch, RootState } from '../../store'
import { Trans, useTranslation } from 'react-i18next'
import EmailField from './fields/EmailField'
import { setFieldEmail } from './fields/redux/fieldsSlice'


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}


function Application() {
  const { id } = useParams()
  const appDefinition = APPLICATIONS[id || 'NONE']
  const dispatch:AppDispatch = useDispatch()
  const { t } = useTranslation('application')
  const [form] = Form.useForm()
  const validatedEmails = useSelector((state:RootState) => state.applications.validatedEmails)
  const attemptGetApplicationDataStatus = useSelector((state:RootState) => state.applications.attemptGetApplicationDataStatus)
  const attemptUpdateApplicationDataStatus = useSelector((state:RootState) => state.applications.attemptUpdateApplicationDataStatus)
  
  const appData = useSelector((state:RootState) => state.applications.data[id || ''])
  let appParsedData:{[field:string]: string}|undefined
  try {
    appParsedData = JSON.parse(appData)
  } catch(e) {}


  useEffect(() => {
    dispatch(attemptGetApplicationData())

    return () => {
      dispatch(idleAttemptGetApplicationDataStatus())
      dispatch(idleAttemptUpdateApplicationDataStatus())
      dispatch(setFieldEmail(''))
    }
  }, [])


  useEffect(() => {
    switch(attemptGetApplicationDataStatus) {
      case 'error':
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('Error fetching your application data'),
        })
        break
    }
  }, [attemptGetApplicationDataStatus])


  useEffect(() => {
    switch(attemptUpdateApplicationDataStatus) {
      case 'success':
        notification.open({
          type: 'success',
          message: t('Success'),
          description: t('Your application data has been successfully persisted')
        })
        break

      case 'error':
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('Error persisting your application data')
        })
        break
    }
  }, [attemptUpdateApplicationDataStatus])


  useEffect(() => {
    if (appParsedData) {
      try {
        form.setFieldsValue({
          email: appParsedData['email'],
        })
        dispatch(setFieldEmail(appParsedData['email']))
      } catch (e) {
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('Could not retrieve application data')
        })
      }
    }
  }, [appParsedData])


  if (!appDefinition) {
    notification.open({
      type: 'error',
      message: t('Unknown application'),
    })

    return <Navigate to="/"/>
  }


  const onFinish = (values: any) => {
    // Save data
    dispatch(attemptUpdateApplicationData({
      name: id || '',
      data: JSON.stringify(values),
    }))
  }


  return (
    <div className="application-page">
      <Header leftItem={<Link to="/applications" style={{ display: "flex" }}><RiArrowGoBackLine size="3em" color="#ffffff" className="header-icon"/></Link>}/>
      <div className="background-gradient"></div>
      
      <div className="main">
        <div className="main-panel">
          <div className="title"><div className="title-icon"><img src={appDefinition.icon}/></div> <h2>{appDefinition.name}</h2></div>

          <div className="sub-title">{t("Account configuration")}</div>

          <p className='black mt-1'>
            <Trans
              i18nKey="ACTION_AUTH_MESSAGE"
              ns="shareData"
              values={{ application: appDefinition.name }}
              components={{ bold: <strong /> }}
            />
          </p>

          <Form
            name="application"
            {...formItemLayout}
            onFinish={onFinish}
            form={form}
          >
            {appDefinition.fields.includes('email') && <EmailField validationVisible={true}/>}

            <div className="application-submit">
              <Button type="primary" htmlType="submit" shape="round">{t("Save")}</Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Application
