import React, { useEffect } from 'react'
import { LanguageSwitch } from '../LanguageSwitch/LanguageSwitch'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { attemptValidateAccount, idleAttemptValidateAccountStatus } from './redux/accountValidateSlice'
import { useTranslation } from 'react-i18next'
import { AppDispatch, RootState } from '../../store'
import { ImSpinner9 } from 'react-icons/im'
import './acccount-validate.scss'
import { BsFillPatchCheckFill } from 'react-icons/bs'
import { VscError } from 'react-icons/vsc'

function AccountValidate() {
  const { token } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation('accountValidate')
  const attemptValidateAccountStatus = useSelector((state:RootState) => state.accountValidate.attemptValidateAccountStatus)

  useEffect(() => {
    if (token) {
      dispatch(attemptValidateAccount({ token }))
    }

    return () => {
      dispatch(idleAttemptValidateAccountStatus())
    }
  }, [])

  return (
    <div className="validation_email_page">
    <div className="background-gradient"></div>
    <img className="logo" src="/images/logo.png"/>
    <h1>Secure & Access</h1>

    <div className="validation_email_result">
      {!token && <><VscError color="#ff4d4f"/>&nbsp;<span>{t('Invalid validation token')}</span></>}
      {token && (
        (attemptValidateAccountStatus === "idle" && <><ImSpinner9 className="icon-spin"/>&nbsp;<span>{t('Wait please.')}</span></>) || 
        (attemptValidateAccountStatus === "loading" && <><ImSpinner9 className="icon-spin"/>&nbsp;<span>{t('Wait please, we are validating your account.')}</span></>) ||
        (attemptValidateAccountStatus === "success" && <><BsFillPatchCheckFill color="#52c41a"/>&nbsp;<span>{t('Congratulations! Your account has been successfully validated.')}</span></>) ||
        (attemptValidateAccountStatus === "error" && <><VscError color="#ff4d4f"/>&nbsp;<span>{t('Error. We could not validate your account.')}</span></>)
      )}
    </div>

    <div>{t('Thank you. You can close this page.')}</div>
  </div>
  
  )
}


export default AccountValidate
