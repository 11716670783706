import React from 'react'
import { useSelector } from "react-redux"
import { Navigate } from "react-router"
import { RootState } from "../../store"

function ShareDataMiddleware({ children }: { children: any }) {
  const app = useSelector((state: RootState) => state.shareData.app)
  const action = useSelector((state: RootState) => state.shareData.action)
  const token = useSelector((state: RootState) => state.shareData.token)

  if (app && action && token) {
    return <Navigate to={`/share-data/${token}/${app}/${action}${window.location.search}`}/>
  }

  return children
}

export default ShareDataMiddleware
