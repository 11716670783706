import React, { useEffect } from 'react'
import { LanguageSwitch } from '../LanguageSwitch/LanguageSwitch'
import { Link, useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import './auth-validation-code.scss'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { Button, Form, Input, notification } from 'antd'
import { attemptVerifyCode, idleAttemptVerifyCodeStatus } from '../Auth/redux/authSlice'
import { MdLogin } from 'react-icons/md'

function AuthValidationCode() {
  const { t } = useTranslation('authValidationCode')
  const [form] = Form.useForm()
  const dispatch:AppDispatch = useDispatch()
  const emailVerificationCode = useSelector((state: RootState) => state.auth.emailVerificationCode)
  const navigate = useNavigate()
  const verifyCodeStatus = useSelector((state: RootState) => state.auth.attemptVerifyCodeStatus)
  const verifyCodeError = useSelector((state: RootState) => state.auth.attemptVerifyCodeError)

  const onFinish = (values: { code: string }) => {
    if (emailVerificationCode) {
      dispatch(attemptVerifyCode({ email: emailVerificationCode, verificationCode: values.code}))
    }
  }

  useEffect(() => {
    return () => {
      dispatch(idleAttemptVerifyCodeStatus())
    }
  }, [])

  useEffect(() => {
    if (verifyCodeStatus === "success") {
      navigate("/auth-methods")
    }
  }, [verifyCodeStatus])

  useEffect(() => {
    switch (verifyCodeError) {
      case "unknown_error":
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('An error occurred, please try later.'),
        })
        break
      case "INVALID_CODE":
        notification.open({
          type: 'error',
          message: t('Verification code does not match.'),
        })
        break
      case "CODE_TIMEOUT":
        notification.open({
          type: 'error',
          message: t('The validation code has expired.'),
        })
        break
    }

  }, [verifyCodeError])

  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }


  return (
    <div className="auth_validation_code">
      <div className="background-gradient"></div>
      
      <div className='logo-block'>
        <img className="logo" src="/images/logo.png"/>
        <h3 className="logo-text">Secure & Access</h3>
      </div>

      <div className='signup-header-container'>
        <h2 className="signup-header-title">{t("Verification code")}
        </h2>
      </div>

      <div className="auth_validation_code-container">
        <span className='language'>
          <LanguageSwitch/>
        </span>
        <p>
          <Trans
            i18nKey="VERIFICATION_CODE"
            shouldUnescape={true}
            ns='auth'
            values={{ email: emailVerificationCode}}
            components={{ bold: <strong /> }}
          />
        </p>
        <p className='mt-1'>{t("Please enter the code received by email to verify your account:")}</p>
        <Form
          form={form}
          name="code"
          initialValues={{ code: '' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={false}
            name="code"
            rules={[
              { required: true, message: t('Please input the verification code') },
            ]}
          >
            <Input
              name="code"
              type="text"
            />
          </Form.Item>

          <Form.Item className="mb-1">
            <Button type="primary" htmlType="submit" block>
              {t("Validate")}
            </Button>
          </Form.Item>
        </Form>
        <p className="sign_up-link_back">
          <Link to="/login"><MdLogin size="2em"/> <span>{t("I got the wrong email.")}</span></Link>
        </p>
      </div>
    </div>
  )
}

export default AuthValidationCode
