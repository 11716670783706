import React from 'react'
import { AiOutlinePoweroff } from 'react-icons/ai'
import { FaCog } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'
import { logoutSecondFactor } from '../../screens/Auth/redux/authSlice'
import "./header.scss"
import { openDrawer } from '../../appSlice'
import { Link } from 'react-router-dom'


export interface HeaderProps {
  leftItem?: React.ReactNode;
}


function Header(props: HeaderProps)
{
  const dispatch:AppDispatch = useDispatch()
  
  function onLogout() {
    dispatch(logoutSecondFactor())
  }

  return (
    <div className="header">
      {props.leftItem || 
        <div className="header-left-item">
          <FaCog size="3em" color="#ffffff" className="header-icon" onClick={() => dispatch(openDrawer())} />
        </div>
      }
      <Link to="/" className="header-center">
        <img className="logo" src="/images/logo.png"/>
        <span className="logo-text">Secure & Access</span>
      </Link>
      <AiOutlinePoweroff size="3em" color="#ffffff" className="header-icon" onClick={onLogout}/>
    </div>
  )
}

export default Header
