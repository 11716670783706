import React, { useEffect } from 'react'
import { Form, Button, notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { AppDispatch, RootState } from '../../store'
import { attemptSignUp, idleAttemptSignUpStatus } from '../Auth/redux/authSlice'
import { useTranslation } from 'react-i18next'
import "./SignUp.scss"
import { MdLogin } from 'react-icons/md'
import { LanguageSwitch } from '../LanguageSwitch/LanguageSwitch'
import FloatInput from '../../components/FloatInput'


function SignUp() 
{
  const [form] = Form.useForm()
  const [platformAuthenticatorForm] = Form.useForm()
  const dispatch:AppDispatch = useDispatch()
  const { t, i18n } = useTranslation('signUp')
  const navigate = useNavigate()
  const user = useSelector((state:RootState) => state.auth.user)
  const attemptSignUpStatus = useSelector((state:RootState) => state.auth.attemptSignUpStatus)
  const webauthnRegisterStatus = useSelector((state:RootState) => state.webAuthn.webauthnRegisterStatus)
  const app = useSelector((state: RootState) => state.shareData.app)

  useEffect(() => {
    if (user) {
      navigate("/sign-up/finalize")
    }
  }, [user])

  useEffect(() => {
    switch (webauthnRegisterStatus) {
      case 'idle':
      case "loading":
      case "success":
        break

      case "EMAIL_PASSWORD_ACCOUNT_ALREADY_EXIST":
        platformAuthenticatorForm.setFields([
          {
            name: 'email',
            errors: [t('EMAIL_PASSWORD_ACCOUNT_ALREADY_EXIST')],
          },
        ])
        break

      case "unknown_error":
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('An error occurred, please try later.'),
        })
        break

      default:
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t(webauthnRegisterStatus),
        })
        break
    }
  }, [webauthnRegisterStatus])


  useEffect(() => {
    switch(attemptSignUpStatus) {
      case 'ACCOUNT_ALREADY_EXIST':
        form.setFields([
          {
            name: 'email',
            errors: [t('This email is already taken')],
          },
        ])
        break

      case 'FIDO2_ACCOUNT_ALREADY_EXIST':
        form.setFields([
          {
            name: 'email',
            errors: [t('FIDO2_ACCOUNT_ALREADY_EXIST')],
          },
        ])
        break

      case 'unknown_error':
        notification.open({
          type: 'error',
          message: 'Error',
          description: t('An error occurred, plase try later')
        })
        break
    }

    return () => {
      dispatch(idleAttemptSignUpStatus())
    }
  }, [attemptSignUpStatus])


  const onFinish = (values: { email: string }) => {
    let locale = "en-GB"
    if (i18n.language === "fr") {
      locale = "fr-FR"
    }

    dispatch(attemptSignUp({ ...values, locale }))
  }


  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }


  return (
    <div className="sign_up">
      <div className="background-gradient"></div>
      
      <div className='logo-block'>
        <img className="logo" src="/images/logo.png"/>
        <h3 className="logo-text">Secure & Access</h3>
      </div>

      <div className='signup-header-container'>
        <h2 className="signup-header-title">{t("Account creation", { ns: "application" })}
          <span className="signup-header-step">{app ? " (1 / 3)" : " (1 / 2)"}</span>
        </h2>
      </div>

      <div className="sign_up-container">
        <span className='language'>
          <LanguageSwitch/>
        </span>
        <Form
          form={form}
          name="basic"
          initialValues={{ email: '' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={false}
            name="email"
            rules={[
              { required: true, message: t('Please input your email', { ns: "application" }) },
              { type: 'email', message: t('Email is not valid', { ns: "application" }) },
            ]}
          >
            <FloatInput
              label={t("Email")}
              name="email"
              type="text"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={attemptSignUpStatus === "loading"}>
              {t('Sign up')}
            </Button>
          </Form.Item>
        </Form>
        <p className="sign_up-link_back">
          <Link to="/login"><MdLogin size="2em"/> <span>{t("I already have an account")}</span></Link>
        </p>
      </div>
    </div>
  )
}

export default SignUp