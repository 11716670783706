import React from 'react'
import { Button } from "antd"
import './pin-button.scss'


function PinButton(props: { symbol?: any, onClick?: any }) {
  return (
    props.symbol 
    ? (
      <Button className="pin_button" shape="circle" type="default" onClick={props.onClick}>
        {props.symbol}
      </Button>
    )
    : <div className="pin_empty_space"/>
  )
}

export default PinButton
