import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import ApiError from '../../../errors/ApiError'
import { setJWT } from '../../Auth/redux/authSlice'


interface State {
  attemptReinitPasswordStatus: string,
  authReinitPasswordTokenStatus: string,
  changePasswordStatus: string,
}


const initialState:State = {
  authReinitPasswordTokenStatus: "idle",
  attemptReinitPasswordStatus: "idle",
  changePasswordStatus: "idle",
}


export const attemptReinitPassword = createAsyncThunk(
  'auth/attemptReinitPassword',
  async (payload: { email: string, locale: string }) => {

    const response = await fetch(process.env.REACT_APP_FIDO_BASE_URL + '/user/forgot-password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }
  }
)


/**
 * Reinit password.
 */
export const authReinitPasswordToken = createAsyncThunk(
  'reinitPassword/authenticateReinitPasswordToken',
  async ({ token }: { token: string }, { dispatch }) => {

    const response = await fetch(process.env.REACT_APP_FIDO_BASE_URL + '/user/reinit-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }

    const body = await response.json()

    dispatch(setJWT(body.jwt))
  }
)


/**
 * Reinit password.
 */
export const changePassword = createAsyncThunk(
  'reinitPassword/changePassword',
  async ({ password }: { password: string }, { getState }) => {

    const { auth } = getState() as { auth: { user: { jwt: string } } }

    const response = await fetch(process.env.REACT_APP_FIDO_BASE_URL + '/user/change-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth.user.jwt}`,
      },
      body: JSON.stringify({ password }),
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }
  }
)



const reinitPasswordSlice = createSlice({
  name: 'reinit-password',
  initialState,
  reducers: {
    idleAttemptReinitPasswordStatus: (state) => {
      state.attemptReinitPasswordStatus = "idle"
    },
    idleAuthReinitPasswordTokenStatus: (state) => {
      state.authReinitPasswordTokenStatus = "idle"
    },
    idleChangePasswordStatus: (state) => {
      state.changePasswordStatus = "idle"
    },
  },
  extraReducers: {
    [attemptReinitPassword.pending.type]: (state, action) => {
      state.attemptReinitPasswordStatus = "loading"
    },
    [attemptReinitPassword.fulfilled.type]: (state, action) => {
      state.attemptReinitPasswordStatus = "success"
    },
    [attemptReinitPassword.rejected.type]: (state, action) => {
      const error = action.error
      state.attemptReinitPasswordStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },
    [authReinitPasswordToken.pending.type]: (state, action) => {
      state.authReinitPasswordTokenStatus = "loading"
    },
    [authReinitPasswordToken.fulfilled.type]: (state, action) => {
      state.authReinitPasswordTokenStatus = "success"
    },
    [authReinitPasswordToken.rejected.type]: (state, action) => {
      const error = action.error
      state.authReinitPasswordTokenStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },

    [changePassword.pending.type]: (state, action) => {
      state.changePasswordStatus = "loading"
    },
    [changePassword.fulfilled.type]: (state, action) => {
      state.changePasswordStatus = "success"
    },
    [changePassword.rejected.type]: (state, action) => {
      const error = action.error
      state.changePasswordStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },
  },
})


export default reinitPasswordSlice.reducer


export const {
  idleAttemptReinitPasswordStatus,
  idleAuthReinitPasswordTokenStatus,
  idleChangePasswordStatus,
} = reinitPasswordSlice.actions
