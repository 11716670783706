import React from 'react'
import {
  BrowserRouter,
  HashRouter,
  Routes,
  Route
} from 'react-router-dom'
import Application from './screens/Applications/Application'
import Applications from './screens/Applications/Applications'
import Auth from './screens/Auth/Auth'
import RequireAuthMiddleware from './screens/Auth/RequireAuthMiddleware'
import Index from './screens/Index/Index'
import PersonalInformation from './screens/PersonalInformation/PersonalInformation'
import SignUp from './screens/SignUp/SignUp'
import QRScan from './screens/QRScan/QRScan'
import ValidationEmail from './screens/Validation/ValidationEmail'
import ShareDataActionDefault from './screens/ShareData/ActionDefault/ShareDataActionDefault'
import ReinitPassword from './screens/ReinitPassword/ReinitPassword'
import ShareDataMiddleware from './screens/ShareData/ShareDataMiddleware'
import SaveShareDataAuthMiddleware from './screens/ShareData/ActionAuth/SaveShareDataAuthMiddleware'
import SaveShareDataMiddleware from './screens/ShareData/ActionDefault/SaveShareDataMiddleware'
import ShareDataActionAuth from './screens/ShareData/ActionAuth/ShareDataActionAuth'
import AuthValidationCode from './screens/AuthValidationCode/AuthValidationCode'
import SetAuthMethods from './screens/SetAuthMethods/SetAuthMethods'
import AccountValidate from './screens/AccountValidate/AccountValidate'


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Auth />} />
          <Route path="/account-verify" element={<AuthValidationCode />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/sign-up/finalize" element={<SetAuthMethods from="signup"/>} />
          <Route path="/auth-methods" element={<SetAuthMethods from="auth"/>} />
          <Route path="/" element={
            <RequireAuthMiddleware>
              <ShareDataMiddleware>
                <Index />
              </ShareDataMiddleware>
            </RequireAuthMiddleware>}
          />
          <Route path="/personal-information" element={
            <RequireAuthMiddleware>
              <ShareDataMiddleware>
                <PersonalInformation />
              </ShareDataMiddleware>
            </RequireAuthMiddleware>}
          />
          <Route path="/applications" element={
            <RequireAuthMiddleware>
              <ShareDataMiddleware>
                <Applications />
              </ShareDataMiddleware>
            </RequireAuthMiddleware>}
          />
          <Route path="/applications/:id" element={
            <RequireAuthMiddleware>
              <ShareDataMiddleware>
                <Application />
              </ShareDataMiddleware>
            </RequireAuthMiddleware>}
          />
          <Route path="/qr-scan" element={
            <RequireAuthMiddleware>
              <ShareDataMiddleware>
                <QRScan />
              </ShareDataMiddleware>
            </RequireAuthMiddleware>}
          />
          <Route path="/share-data/:token/:app/auth" element={
            <SaveShareDataAuthMiddleware>
              <RequireAuthMiddleware>
                <ShareDataActionAuth />
              </RequireAuthMiddleware>
            </SaveShareDataAuthMiddleware>}
          />
          <Route path="/share-data/:token/:app/:action" element={
            <SaveShareDataMiddleware>
              <RequireAuthMiddleware>
                <ShareDataActionDefault />
              </RequireAuthMiddleware>
            </SaveShareDataMiddleware>}
          />
          <Route path="/validate/email/:token" element={<ValidationEmail />} />
          <Route path="/reinit-password/:token" element={<ReinitPassword />} />
          <Route path="/account_validation/:token" element={<AccountValidate/>} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
