import React from 'react'
import { Form, AutoComplete } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { useTranslation } from 'react-i18next'
import ValidationEmailStatus from './ValidationEmailStatus'
import { setFieldEmail } from './redux/fieldsSlice'
import "./EmailField.scss"
import AutocompleteFloatInput from '../../../components/AutocompleteFloatInput'


interface Props {
  validationVisible: boolean
}


function EmailField({ validationVisible }: Props) {
  const { t } = useTranslation('application')
  const dispatch:AppDispatch = useDispatch()
  const validatedEmails = useSelector((state:RootState) => state.applications.validatedEmails)
  const user = useSelector((state:RootState) => state.auth.user)

  const emailOptions = Object.keys(validatedEmails).map(value => ({ label: value, value }))
  if (user?.email && !validatedEmails[user?.email]) {
    emailOptions.push({ label: user?.email, value: user?.email })
  }

  const emailValue = useSelector((state:RootState) => state.fields.email)

  return (
    <>
      <Form.Item
        label={false}
        wrapperCol={{ span: 24 }}
        name="email"
        className="mb-05 mt-1"
        rules={[
          { required: true, message: t('Please input your email', { ns: "application" }) },
          { type: 'email', message: t('Email is not valid', { ns: "application" }) },
        ]}
      >
        <AutocompleteFloatInput
          label={t("Email")}
          onChange={(value: string) => dispatch(setFieldEmail(value))}
          options={emailOptions}
          filterOption={(inputValue, option) =>
            option?.value?.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
        />
      </Form.Item>
      {validationVisible && emailValue && (
        <ValidationEmailStatus />
      )}
    </>
  )
}

export default EmailField
