import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'
import { RootState } from '../../../store'


class ApiError extends Error {
  constructor(message: string) {
    super(message || "unknown_error")
    this.name = "ApiError"
  }
}


type SecondFactor = "PIN" | "FIDO2" | "NONE"


interface State {
  attemptAuthStatus: string,
  attemptAuthError?: string,
  attemptSignUpStatus: string,
  attemptVerifyCodeStatus: string,
  attemptVerifyCodeError?: string,
  emailVerificationCode?: string,
  verificationCode?: string
  user?: {
    jwt: string,
    email: string,
    jwtFido2: boolean,
    secondFactor?: SecondFactor,
    iat: string,
  },
  pins: {
    [email: string]: {
      pin: string,
      wrongTriesLeft: number,
    },
  },
}


const initialState:State = {
  attemptAuthStatus: "idle",
  attemptAuthError: "idle",
  attemptSignUpStatus: "idle",
  attemptVerifyCodeStatus: "idle",
  attemptVerifyCodeError: "idle",
  pins: {},
}


/**
 * Auth user with email
 */
export const attemptAuth = createAsyncThunk(
  'auth/attemptAuth',
  async (payload: { email: string, locale: string, skipSecondFactor?: boolean }, { dispatch }) => {

    const response = await fetch(process.env.REACT_APP_FIDO_BASE_URL + '/user/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      const reason = (await response.json()).errorMessage || "unknown_error"
      throw new ApiError(reason)
    }

    dispatch(setEmailVerificationCode(payload.email))
  }
)


/**
 * Auth user with email and password.
 */
export const attemptSignUp = createAsyncThunk(
  'auth/attemptSignUp',
  async (payload: { email: string, locale: string }, { dispatch }) => {
    const response = await fetch(process.env.REACT_APP_FIDO_BASE_URL + '/user/create', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      const reason = (await response.json()).errorMessage || "unknown_error"
      throw new ApiError(reason)
    }

    const body = await response.json()

    dispatch(setJWT(body.jwt))
  }
)

export const attemptVerifyCode = createAsyncThunk(
  'auth/attemptVerifyCode',
  async (payload: { email: string, verificationCode: string }, { dispatch }) => {

    const response = await fetch(process.env.REACT_APP_FIDO_BASE_URL + '/user/verify-account', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      const reason = (await response.json()).errorMessage || "unknown_error"
      throw new ApiError(reason)
    }

    const body = await response.json()

    dispatch(setJWT(body.jwt))
  }
)


export const attemptValidateAccount = createAsyncThunk(
  'auth/attemptValidateAccount',
  async ({ token }: { token: any }, { dispatch }) => {

    const response = await fetch(process.env.REACT_APP_FIDO_BASE_URL + '/user/validate-account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }

    const body = await response.json()

    dispatch(setJWT(body.jwt))
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setJWT: (state, action: PayloadAction<string>) => {
      const jwt = action.payload
      const { email, secondFactor, iat }: { email: string, secondFactor?: SecondFactor, iat: string, issuer?: string } = jwtDecode(jwt)
      if (!email) {
        throw new Error('Invalid JWT')
      }

      if (!state.pins) {
        state.pins = {}
      }

      const currentPIN = state.pins[email.toLowerCase()] ? state.pins[email.toLowerCase()].pin : undefined
      
      state.user = {
        jwt,
        jwtFido2: secondFactor === "FIDO2",
        email,
        secondFactor: secondFactor || (currentPIN ? "PIN" : undefined),
        iat,
      }
    },
    setSecondFactorNone: (state) => {
      if (state.user) {
        state.user.secondFactor = "NONE"
      }
    },
    idleAttemptAuthStatus: (state ) => {
      state.attemptAuthStatus = "idle"
      state.attemptAuthError = undefined
    },
    idleAttemptVerifyCodeStatus: (state) => {
      state.attemptVerifyCodeStatus = "idle"
      state.attemptVerifyCodeError = undefined
    },
    idleAttemptSignUpStatus: (state) => {
      state.attemptSignUpStatus = "idle"
    },
    logoutJWT: (state) => {
      state.user = undefined
    },
    logoutSecondFactor: (state) => {
      if (state.user) {
        state.user.secondFactor = undefined
      }
    },
    setPin: (state, { payload }: PayloadAction<string>) => {
      if (!state.user) {
        return
      }
      if (!state.pins) {
        state.pins = {}
      }
      state.pins[state.user.email.toLowerCase()] = { pin: payload, wrongTriesLeft: 3 }
      state.user.secondFactor = "PIN"
    },
    setEmailVerificationCode: (state, { payload }: PayloadAction<string>) => {
      state.emailVerificationCode = payload
    },
    forgetPin: (state, { payload }: PayloadAction<string>) => {
      const email = payload.toLowerCase()
      if (!state.pins) {
        state.pins = {}
      }
      state.user = undefined
      delete state.pins[email]
    },
    correctPinEntered: (state) => {
      if (!state.user || !state.pins) {
        return
      }

      state.pins[state.user.email.toLowerCase()].wrongTriesLeft = 3
      state.user.secondFactor = "PIN"
    },

    wrongPinEntered: (state, { payload }: PayloadAction<string>) => {
      const email = payload.toLowerCase()

      state.pins[email].wrongTriesLeft--
      if (state.pins[email].wrongTriesLeft === 0) {
        state.user = undefined
        delete state.pins[email]
      }
    }
  },

  extraReducers: {
    [attemptAuth.pending.type]: (state, action) => {
      state.attemptAuthStatus = "loading"
      state.attemptAuthError = undefined
    },
    [attemptAuth.fulfilled.type]: (state, action) => {
      state.attemptAuthStatus = "success"
      state.attemptAuthError = undefined
    },
    [attemptAuth.rejected.type]: (state, action) => {
      const error = action.error
      state.attemptAuthStatus = "error"
      state.attemptAuthError = error.name === "ApiError" ? error.message : "unknown_error"
    },

    [attemptVerifyCode.pending.type]: (state, action) => {
      state.attemptVerifyCodeStatus = "loading"
      state.attemptVerifyCodeError = undefined
    },
    [attemptVerifyCode.fulfilled.type]: (state, action) => {
      state.attemptVerifyCodeStatus = "success"
      state.attemptVerifyCodeError = undefined
    },
    [attemptVerifyCode.rejected.type]: (state, action) => {
      const error = action.error
      state.attemptVerifyCodeError = error.name === "ApiError" ? error.message : "unknown_error"
    },

    [attemptSignUp.pending.type]: (state, action) => {
      state.attemptSignUpStatus = "loading"
    },
    [attemptSignUp.fulfilled.type]: (state, action) => {
      state.attemptSignUpStatus = "idle"
    },
    [attemptSignUp.rejected.type]: (state, action) => {
      const error = action.error
      console.error(error)
      state.attemptSignUpStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },
  },
  
})


export default authSlice.reducer


export const selectPIN = (state: RootState) => {
  const email = state.auth.user?.email
  return state.auth.pins?.[email?.toLocaleLowerCase() || '']
}


export const {
  setJWT,
  setEmailVerificationCode,
  setSecondFactorNone,
  idleAttemptAuthStatus,
  idleAttemptVerifyCodeStatus,
  idleAttemptSignUpStatus,
  logoutJWT,
  logoutSecondFactor,
  setPin,
  forgetPin,
  correctPinEntered,
  wrongPinEntered,
} = authSlice.actions
