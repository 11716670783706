import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import ApiError from '../../../errors/ApiError'
import { FetchStatus } from '../../../utils/FetchStatus'


interface State {
  token?: string
  app?: string
  action?: string
  acceptShareDataStatus: FetchStatus
  acceptShareDataError?: string
  acceptShareDataSuccessRedirectTo?: string
}


const initialState:State = {
  acceptShareDataStatus: "idle",
}


/**
 * Auth user with email and password.
 */
export const acceptShareData = createAsyncThunk(
  'shareData/accept',
  async (payload: { token: string, data?: string }, { getState }) => {
    const { auth } = getState() as { auth: { user: { jwt: string } } }

    const response = await fetch(process.env.REACT_APP_RELAY_BASE_URL + '/data-share/authorize', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth.user.jwt}`,
      },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }

    return await response.json()
  }
)


const shareDataSlice = createSlice({
  name: 'share-data',
  initialState,
  reducers: {
    setShareData: (state, action: PayloadAction<{ token: string, app: string, action: string }>) => {
      state.token = action.payload.token
      state.app = action.payload.app
      state.action = action.payload.action
    },
    emptyShareData: (state) => {
      state.token = undefined
      state.app = undefined
      state.action = undefined
    },
    idleAcceptShareDataStatus: (state) => {
      state.acceptShareDataStatus = "idle"
      state.acceptShareDataError = undefined
      state.acceptShareDataSuccessRedirectTo = undefined
    },
  },
  extraReducers: {
    [acceptShareData.pending.type]: (state, action) => {
      state.acceptShareDataStatus = "loading"
      state.acceptShareDataError = undefined
      state.acceptShareDataSuccessRedirectTo = undefined
    },
    [acceptShareData.fulfilled.type]: (state, { payload }: PayloadAction<{ redirectTo?: string}>) => {
      state.acceptShareDataStatus = "success"
      state.acceptShareDataError = undefined
      state.acceptShareDataSuccessRedirectTo = payload.redirectTo
      state.token = undefined
      state.app = undefined
      state.action = undefined
    },
    [acceptShareData.rejected.type]: (state, action) => {
      const error = action.error
      state.acceptShareDataStatus = "error"
      state.acceptShareDataError = error.name === "ApiError" ? error.message : "unknown_error"
      state.acceptShareDataSuccessRedirectTo = undefined
      state.token = undefined
      state.app = undefined
      state.action = undefined
    },
  }
})


export default shareDataSlice.reducer


export const {
  setShareData,
  emptyShareData,
  idleAcceptShareDataStatus,
} = shareDataSlice.actions
