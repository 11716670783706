import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Header from '../../components/Header/Header'
import Drawer from '../../components/Drawer/Drawer'
import './index.scss'
import { notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { idleAttemptSendAccountValidationStatus } from '../AccountValidate/redux/accountValidateSlice'


function Index()
{
  const { t } = useTranslation('index')
  const sendAccountValidationStatus = useSelector((state:RootState) => state.accountValidate.attemptSendAccountValidationStatus)
  const sendAccountValidationError = useSelector((state:RootState) => state.accountValidate.attemptSendAccountValidationError)
  const user = useSelector((state:RootState) => state.auth.user)
  const dispatch:AppDispatch = useDispatch()

  useEffect(() => {
    if (sendAccountValidationStatus === "success") {
      notification.open({
        type: 'info',
        message: <Trans
          i18nKey="ACCOUNT_VALIDATION"
          shouldUnescape={true}
          ns='setAuthMethods'
          values={{ email: user?.email}}
          components={{ bold: <strong /> }}
        />
      })
    }
  }, [sendAccountValidationStatus])


  useEffect(() => {
    switch (sendAccountValidationError) {
      case "unknown_error":
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('An error occurred, please try later.'),
        })
        break
      case "ACCOUNT_ALREADY_VALIDATED":
        notification.open({
          type: 'warning',
          message: t('Your account is already validated.', { ns: 'setAuthMethods'}),
        })
        break
    }
  }, [sendAccountValidationError])


  useEffect(() => {
    return () => {
      dispatch(idleAttemptSendAccountValidationStatus())
    }
  }, [])


  return (
    <>
      <div className="index">
        <Header/>
        <div className="background-gradient"/>
        <main className="main">
          <div className="menu-row">
            <Link to="/qr-scan" className="menu-item">
              <div className="menu-item-image">
                <img src="/images/qr-code.svg" />
              </div>
              <div className="menu-item-text">{t('Scan QR-code')}</div>
            </Link>
          </div>
          <div className="menu-row">
            <Link to="/personal-information" className="menu-item">
              <div className="menu-item-image">
                <img src="/images/personal-information.svg" />
              </div>
              <div className="menu-item-text">{t("My personal information")}</div>
            </Link>
            <Link to="/applications" className="menu-item">
              <div className="menu-item-image">
                <img src="/images/application.svg" />
              </div>
              <div className="menu-item-text">{t("Applications")}</div>
            </Link>
          </div>
        </main>
      </div>
    <Drawer/>
    </>
  )
}

export default Index