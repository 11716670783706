import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router'
import { AppDispatch, RootState } from '../../../store'
import { idleAcceptShareDataStatus, acceptShareData, emptyShareData } from '../redux/shareDataSlice'
import { APPLICATIONS, checkAppAndAction } from '../../Applications/apps'
import { Button, notification } from 'antd'
import { BiError } from 'react-icons/bi'
import { attemptGetApplicationData, idleAttemptGetApplicationDataStatus } from '../../Applications/redux/applicationsSlice'
import ShareDataComponent from '../ShareDataComponent/ShareDataComponent'
import "../ShareData.scss"
import { useTranslation } from 'react-i18next'


function ShareDataActionDefault() {
  const { t } = useTranslation('shareData')
  const { token, app, action } = useParams()
  const dispatch:AppDispatch = useDispatch()
  const navigate = useNavigate()
  const acceptShareDataStatus = useSelector((state:RootState) => state.shareData.acceptShareDataStatus)
  const acceptShareDataError = useSelector((state:RootState) => state.shareData.acceptShareDataError)
  
  const appData = useSelector((state:RootState) => state.applications.data ? state.applications.data[app || ''] : '')
  let appParsedData:{[field:string]: string}|undefined
  try {
    appParsedData = JSON.parse(appData)
  } catch(e) {}

  const appDefinition = APPLICATIONS[app || ""]
  const actionDefinition = appDefinition?.actions[action || ""]


  useEffect(() => {
    dispatch(attemptGetApplicationData())

    return () => {
      dispatch(emptyShareData())
      dispatch(idleAttemptGetApplicationDataStatus())
    }
  }, [])


  useEffect(() => {
    switch (acceptShareDataStatus) {
      case 'success':
        notification.open({
          type: 'success',
          message: t("Success", { ns: 'application'}),
          description: t('Successfully shared your data.'),
        })
        navigate('/')
        break
      
      case 'error':
        switch (acceptShareDataError) {
          case 'REQUIRED_DATA_MISSING':
            notification.open({
              type: 'error',
              message: t('Could not share data'),
              description: t('You did not enter required data for this application.')
            })
            break

          case "EMAIL_NOT_VALIDATED":
            notification.open({
              type: 'error',
              message: t('Could not authenticate'),
              description: t('You did not validated required data for this application.')
            })
            break

          case 'UNKNOWN_TOKEN':
          case 'CLIENT_DISCONNECTED':
          default:
            notification.open({
              type: 'error',
              message:  t('Unexpected error', { ns: 'application'}),
              description: t('Could not share data. Please scan QR-code again.'),
            })
            navigate('/')
            break
        }
    }

    return () => {
      dispatch(idleAcceptShareDataStatus())
    }
  }, [acceptShareDataStatus])


  if (!app || !action || !appDefinition || !actionDefinition) {
    notification.open({
      type: 'error',
      message: t('Could not share data'),
      description: t('Invalid application and action'),
    })
    
    return <Navigate to="/"/>
  }


  function accept() {
    dispatch(acceptShareData({ token: token || "" }))
  }


  function decline() {
    dispatch(emptyShareData())
    navigate('/')
  }


  return (
    <div className="share_data_page">
      <div className="background-gradient"></div>
      <img className="logo" src="/images/logo.png"/>
      <h1>Secure & Access</h1>
      <div className="share_data_panel">
        {token && checkAppAndAction(app, action)
          ? <ShareDataComponent 
              app={app || ""}
              appDefinition={appDefinition}
              action={action || ""}
              actionDefinition={actionDefinition}
              accept={accept}
              decline={decline} />
          : <div className="share_data_invalid_container">
              <BiError color="#ff4d4f" size="4em"/>
              <div className="share_data_invalid_text">{t('Invalid token')}</div>
              <Button href="/" type="default" className="mt-1">{t('Go to homepage')}</Button>
            </div>
        }
      </div>
    </div>
  )
}

export default ShareDataActionDefault
