import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, notification } from 'antd'
import { AppDispatch, RootState } from '../../../store'
import { Trans, useTranslation } from 'react-i18next'
import { APPLICATIONS } from '../../Applications/apps'
import { useNavigate, useParams } from 'react-router'
import { acceptShareData, emptyShareData, idleAcceptShareDataStatus } from '../redux/shareDataSlice'
import { attemptGetApplicationData, idleAttemptGetApplicationDataStatus } from '../../Applications/redux/applicationsSlice'
import { ImSpinner9 } from 'react-icons/im'
import "../ShareData.scss"
import "./ShareDataActionAuth.scss"
import { useSearchParams } from 'react-router-dom'
import { setFieldEmail } from '../../Applications/fields/redux/fieldsSlice'
import EmailField from '../../Applications/fields/EmailField'
import { BsFillPatchCheckFill } from 'react-icons/bs'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}

function ShareDataActionAuth() {
  const { token, app } = useParams()
  const dispatch:AppDispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('shareData')
  const [form] = Form.useForm()
  const appDefinition = APPLICATIONS[app || ""]
  const actionDefinition = appDefinition?.actions["auth"]
  const [validationSent, setValidationSent] = useState<boolean>(false)
  const appData = useSelector((state:RootState) => state.applications.data ? state.applications.data[app || ''] : '')
  const acceptShareDataStatus = useSelector((state:RootState) => state.shareData.acceptShareDataStatus)
  const acceptShareDataSuccessRedirectTo = useSelector((state:RootState) => state.shareData.acceptShareDataSuccessRedirectTo)
  const acceptShareDataError = useSelector((state:RootState) => state.shareData.acceptShareDataError)
  const attemptGetApplicationDataStatus = useSelector((state:RootState) => state.applications.attemptGetApplicationDataStatus)
  const [searchParams] = useSearchParams()

  let appParsedData:{[field:string]: string}|undefined
  try {
    appParsedData = JSON.parse(appData)
  } catch(e) {}
  const emailValue = appParsedData ? appParsedData['email'] : undefined


  useEffect(() => {
    dispatch(attemptGetApplicationData())

    return () => {
      dispatch(idleAttemptGetApplicationDataStatus())
      dispatch(idleAcceptShareDataStatus())
      dispatch(emptyShareData())
      dispatch(setFieldEmail(''))
    }
  }, [])


  useEffect(() => {
    switch(attemptGetApplicationDataStatus) {
      case "success":
        if (appData && emailValue) {
          form.setFieldsValue({email: emailValue})
          accept()
        }
        break

      case "error":
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('An error occurred, please try later.')
        })
        break
    }
  }, [attemptGetApplicationDataStatus])


  useEffect(() => {
    switch (acceptShareDataStatus) {
      case 'success':
        if (!acceptShareDataSuccessRedirectTo) {
          notification.open({
            type: 'success',
            message: t("Success", { ns: 'application'}),
            description: t('Successfully shared your data.'),
          })
          navigate('/')
        }
        break
      
      case 'error':
        switch (acceptShareDataError) {
          case 'REQUIRED_DATA_MISSING':
            notification.open({
              type: 'error',
              message: t('Could not authenticate'),
              description: t('You did not enter required data for this application.')
            })
            break
    
          case "EMAIL_NOT_VALIDATED":
            setValidationSent(true)
            break

          case 'UNKNOWN_TOKEN':
          case 'CLIENT_DISCONNECTED':
          default:
            notification.open({
              type: 'error',
              message: t('Unexpected error'),
              description: t('Could not authenticate'),
            })
            navigate('/')
            break
        }
    }
  }, [acceptShareDataStatus])

  function accept(values?: any) {
    dispatch(acceptShareData({ token: token || "", data: JSON.stringify(values) }))
  }

  return (
    <div className="share_data_page">
      <div className="background-gradient"/>
      <div className='logo-block'>
        <img className="logo" src="/images/logo.png"/>
        <h3 className="logo-text">Secure & Access</h3>
      </div>

      {searchParams.get('from') === 'signup' && (
        <div className='signup-header-container'>
          <h2 className="signup-header-title">{t("Account creation", { ns: "application" })}
            <span className="signup-header-step"> {t("(3 / 3)")}</span>
          </h2>
        </div>
      )}

      {acceptShareDataStatus === "success" 
        ? (
          <div className='share_data_success'>
            <div className='share_data_success_header'>
              <BsFillPatchCheckFill color="#52c41a"/>
              <span>{t('Your authentication data has been successfully shared.')}</span>
            </div>

            <div>
              <Button
                type="default"
                href='/'
                className='share_data_success_button'
              >
                {t('Back to the homepage')}
              </Button>
              {acceptShareDataSuccessRedirectTo && (
                <Button 
                  type='default' 
                  href={acceptShareDataSuccessRedirectTo}
                  className='share_data_success_button'
                >
                  {t('Return to the application')}
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="share_data_panel">
            <div className="share_data_title">
              <div className="application-image"><img src={appDefinition.icon}/></div>
              {appDefinition.name}
            </div>
            {attemptGetApplicationDataStatus === "loading" || acceptShareDataStatus === "loading"
              ? (
                <div className="mt-1 text-align-center">
                  <ImSpinner9 size="3em" className="icon-spin mb-1"/>
                  <h3>{t('Wait please. Your request is being processed.')}</h3>
                </div>
              ) : (
                validationSent
                ? <>
                    <div className='d-flex d-flex-center d-flex-middle d-flex-colmn g-1 mt-1'>
                      <span>
                        <Trans 
                          i18nKey="email_validation_message"
                          ns="shareData"/>
                      </span>
                      <span className='bold'>{form.getFieldValue('email')}</span>
                    </div>
                    <div className="text-align-center">
                      <Button type="link" onClick={() => setValidationSent(false)}>{t('Change email')}</Button>
                    </div>
                    <div className="share_data_auth_actions">
                      <Button type="primary" href='/'>
                        {t('OK')}
                      </Button>
                    </div>
                  </>
                : (
                  <>
                    <div className="mt-1">
                      <Trans
                        i18nKey="ACTION_AUTH_MESSAGE"
                        ns="shareData"
                        values={{ application: appDefinition.name }}
                        components={{ bold: <strong /> }}
                      />
                    </div>

                    <Form
                      name="application"
                      {...formItemLayout}
                      onFinish={accept}
                      form={form}
                    >
                      {appDefinition.fields.includes('email') && <EmailField validationVisible={false}/>}

                      <div className="share_data_actions">
                        <Button danger href="/">{t('Cancel')}</Button>
                        <Button type="primary" htmlType="submit">{t('Continue')}</Button>
                      </div>
                    </Form>
                  </>
                )
              )
            }
          </div>
        )
      }
    </div>
    
  )
}

export default ShareDataActionAuth
