import React, { useEffect } from 'react'
import Header from '../../components/Header/Header'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { APPLICATIONS } from './apps'
import "./applications.scss"
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { attemptGetApplicationData, idleAttemptGetApplicationDataStatus } from './redux/applicationsSlice'
import { Spin, notification } from 'antd'
import { useTranslation } from 'react-i18next'


function Applications() {
  const { t } = useTranslation('application')
  const dispatch:AppDispatch = useDispatch()
  const attemptGetApplicationDataStatus = useSelector((state:RootState) => state.applications.attemptGetApplicationDataStatus)
  const data = useSelector((state:RootState) => state.applications.data)

  const availableApps = Object.entries(APPLICATIONS)
    .filter(([id, app]) => {
      return !!data[id]
    })

  useEffect(() => {
    dispatch(attemptGetApplicationData())

    return () => {
      dispatch(idleAttemptGetApplicationDataStatus())
    }
  }, [])

  useEffect(() => {
    switch(attemptGetApplicationDataStatus) {
      case 'error':
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('Error fetching your application data'),
        })
        break
    }
  }, [attemptGetApplicationDataStatus])

  return (
    <div className="applications-page">
      <Header leftItem={<Link to="/" style={{ display: "flex" }}><RiArrowGoBackLine size="3em" color="#ffffff" className="header-icon"/></Link>}/>
      <div className="background-gradient"></div>
      
      <main className="main">
        {attemptGetApplicationDataStatus === "loading" && (
          <Spin size='large'/>
        )}

        {attemptGetApplicationDataStatus === "success" && (
          availableApps.length > 0 
            ? (
              availableApps
                .map(([id, app]) => {
                  return (
                    <Link to={'/applications/' + id} key={id} className="application">
                      <div className="application-image"><img src={app.icon}/></div>
                      <div className="application-text">{app.name}</div>
                    </Link>
                  )
                })
            )
            : (
              <div>{t('You have not used any application yet.')}</div>
            )
        )}
      </main>
    </div>
  )
}

export default Applications
