import TemmaMedecinIcon from "./resources/temma-medecin.svg"
import TemmaOperatorIcon from "./resources/temma-operator.svg"
import TemmaPatientIcon from "./resources/temma-patient.svg"
import AuxamedIcon from "./resources/auxamed.svg"
import AuxaviewIcon from "./resources/auxaview.svg"
import FribourgIcon from "./resources/fribourg.png"
import CircamedIcon from "./resources/circamed.png"
import DecidelifeIcon from "./resources/decidelife.png"
import WeedooIcon from "./resources/weedoo.png"
import IntechfloorIcon from "./resources/intechfloor.png"
import PrecosIcon from "./resources/precos.png"
import InspireIcon from "./resources/inspire.png"
import LifeForTrackingIcon from "./resources/lifefortracking.png"
import FondationUniversiteParisSaclayIcon from "./resources/fondationuniversiteparis-saclay.png"
import LifinsightIcon from "./resources/lifinsight.png"
import AuxasuiteIcon from "./resources/auxasuite.png"


interface AppActionFieldDefinition  {
  required: boolean,
  validated: boolean,
}


export interface AppActionDefinition {
  name: string,
  shareFields: { [key: string]: AppActionFieldDefinition },
  error_message_missed_fields: string,
}


export interface AppDefinition {
  name: string,
  icon: string,
  fields: string[],
  actions: { [key: string]: AppActionDefinition },
}


export const APPLICATIONS: { [key: string]: AppDefinition } = {
  "temma-medecin": {
    name: "Temma medecin",
    fields: ["email"],
    icon: TemmaMedecinIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "temma-operator": {
    name: "Temma operator",
    fields: ["email"],
    icon: TemmaOperatorIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "temma-patient": {
    name: "Temma patient",
    fields: ["email"],
    icon: TemmaPatientIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "auxamed": {
    name: "Auxamed",
    fields: ['email'],
    icon: AuxamedIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "auxaview": {
    name: "Auxaview",
    fields: ['email'],
    icon: AuxaviewIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "fribourg": {
    name: "Hôpital Fribourg",
    fields: ['email'],
    icon: FribourgIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "circamed": {
    name: "Circamed",
    fields: ['email'],
    icon: CircamedIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "decidelife": {
    name: "Decide Life",
    fields: ['email'],
    icon: DecidelifeIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "weedoo": {
    name: "WeeDoo",
    fields: ['email'],
    icon: WeedooIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "intechfloor": {
    name: "Intechfloor",
    fields: ['email'],
    icon: IntechfloorIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "precos": {
    name: "Precos",
    fields: ['email'],
    icon: PrecosIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "inspire": {
    name: "Inspire",
    fields: ['email'],
    icon: InspireIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "lifefortracking": {
    name: "Life Care Medicine",
    fields: ['email'],
    icon: LifeForTrackingIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "fondationuniversiteparis-saclay": {
    name: "Fondation Université Paris-Saclay",
    fields: ['email'],
    icon: FondationUniversiteParisSaclayIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "lifinsight": {
    name: "Lifinsight",
    fields: ['email'],
    icon: LifinsightIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "auxasuite": {
    name: "Auxasuite",
    fields: ['email'],
    icon: AuxasuiteIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
}


export function checkAppAndAction(app?: string, action?: string) {
  return app && APPLICATIONS[app] && action && APPLICATIONS[app].actions[action]
}
