import React from 'react'
import { RootState } from '../../../store'
import { useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import "./ValidationEmailStatus.scss"


function ValidationEmailStatus() {
  const { t } = useTranslation('application')
  const value = useSelector((state:RootState) => state.fields.email)
  const validatedEmails = useSelector((state:RootState) => state.applications.validatedEmails)

  if (value) {
    switch (validatedEmails[value]) {
      case "pending":
        return <div className="validation_email_status-feedback">
          <Trans
            i18nKey="EMAIL_VALIDATION_PENDING"
            ns="application"
            values={{ email: value }}
            components={{ bold: <strong /> }}
          />
        </div>
  
      case "validated":
        return <div className="validation_email_status-feedback">{t("Your email has been validated!")}</div>
    }
  }

  return <></>
}


export default ValidationEmailStatus
