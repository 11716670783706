import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import authEN from './locales/auth.en.json'
import authFR from './locales/auth.fr.json'
import signUpEN from './locales/sign-up.en.json'
import signUpFR from './locales/sign-up.fr.json'
import setAuthMethodsEN from './locales/set-auth-methods.en.json'
import setAuthMethodsFR from './locales/set-auth-methods.fr.json'
import authPinEN from './locales/auth-pin.en.json'
import authPinFR from './locales/auth-pin.fr.json'
import createPinEN from './locales/create-pin.en.json'
import createPinFR from './locales/create-pin.fr.json'
import indexEN from './locales/index.en.json'
import indexFR from './locales/index.fr.json'
import applicationEN from './locales/application.en.json'
import applicationFR from './locales/application.fr.json'
import shareDataEN from './locales/share-data.en.json'
import shareDataFR from './locales/share-data.fr.json'
import authValidationCodeFR from './locales/auth-validation-code.fr.json'
import authValidationCodeEN from './locales/auth-validation-code.en.json'
import accountValidateEN from './locales/account-validate.en.json'
import accountValidateFR from './locales/account-validate.fr.json'

export const resources = {
  en: {
    auth: authEN,
    signUp: signUpEN,
    setAuthMethods: setAuthMethodsEN,
    authPin: authPinEN,
    createPin: createPinEN,
    index: indexEN,
    application: applicationEN,
    shareData: shareDataEN,
    authValidationCode: authValidationCodeEN,
    accountValidate: accountValidateEN
  },
  fr: {
    auth: authFR,
    signUp: signUpFR,
    setAuthMethods: setAuthMethodsFR,
    authPin: authPinFR,
    createPin: createPinFR,
    index: indexFR,
    application: applicationFR,
    shareData: shareDataFR,
    authValidationCode: authValidationCodeFR,
    accountValidate: accountValidateFR
  },
} as const;


i18n
  .use(initReactI18next)
  .init({
    lng: 'fr',
    fallbackLng: 'en',
    ns: ['auth', 'signUp', 'setAuthMethods', 'authPin', 'createPin', 'authCreateSecondFactor', 'index', 'application', 'shareData', 'authValidationCode', 'accountValidate'],
    resources,
  })
  
