import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Form, Input, Button, notification, Tabs, Spin, Alert } from 'antd'
import { attemptAuth, idleAttemptAuthStatus, logoutJWT, selectPIN } from './redux/authSlice'
import { AppDispatch, RootState } from '../../store'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { IoMdFingerPrint } from 'react-icons/io'
import { LanguageSwitch } from '../LanguageSwitch/LanguageSwitch'
import './Auth.scss'
import { checkPlatformAuthenticatorAvailable } from '../../webauthn/webAuthnUtils'
import { attemptWebauthnAuthenticateOrRegister, attemptWebauthnAuthenticate, idleWebauthnAuthenticateStatus, idleWebauthnAuthenticateOrRegisterStatus } from '../../webauthn/webAuthnSlice'
import FloatInput from '../../components/FloatInput'
import AuthPin from '../AuthCreateSecondFactor/AuthPin/AuthPin'
import CreatePin from '../AuthCreateSecondFactor/CreatePin/CreatePin'
import { BsArrowLeftShort } from 'react-icons/bs'
import { AiFillExclamationCircle } from 'react-icons/ai'
import { ConnectionMethod } from '../../components/AuthMethods/ConnectionMethod'
const { TabPane } = Tabs


function Auth()
{
  const [form] = Form.useForm()
  const [platformAuthenticatorForm] = Form.useForm()
  const dispatch:AppDispatch = useDispatch()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation('auth')
  const user = useSelector((state:RootState) => state.auth.user)
  const attemptAuthStatus = useSelector((state:RootState) => state.auth.attemptAuthStatus)
  const attemptAuthError = useSelector((state:RootState) => state.auth.attemptAuthError)
  const webauthnAuthenticateStatus = useSelector((state:RootState) => state.webAuthn.webauthnAuthenticateStatus)
  const webauthnAuthenticateOrRegisterStatus = useSelector((state:RootState) => state.webAuthn.webauthnAuthenticateOrRegisterStatus)
  const storedPin = useSelector(selectPIN)
  const [platformAuthenticatorAvailable, setPlatformAuthenticatorAvailable] = useState(false)
  const [platformAuthenticatorEmail, setPlatformAuthenticatorEmail] = useState('')
  const [connMethod, setConnMethod] = useState<ConnectionMethod | undefined>()
  

  useEffect(() => {
    if (user && user.secondFactor) {
      navigate('/')
    }

    if (user) {
      if (!storedPin && platformAuthenticatorAvailable) {
        setConnMethod(ConnectionMethod.BIOMETRIC)
      } else {
        setConnMethod(ConnectionMethod.PIN)
      }
    }
  }, [user])


  /* Disable biometric until iPhone bug is resolved.
  useEffect(() => {
    checkPlatformAuthenticatorAvailable()
      .then((available) => {
        setPlatformAuthenticatorAvailable(available)
        if (available && !storedPin) {
          setTabActiveKey("biometric")
        }
      })
  }, [])
  */


  useEffect(() => {
    switch (webauthnAuthenticateStatus) {
      case "unknown_error":
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('An error occurred, please try later.'),
        })
        break
    }

    return () => {
      dispatch(idleWebauthnAuthenticateStatus())
    }
  }, [webauthnAuthenticateStatus])

  useEffect(() => {
    switch(attemptAuthStatus) {
      case 'success':
        navigate("/account-verify")
        break
      case 'error':
        switch(attemptAuthError) {
          case 'USERNAME_NOT_FOUND':
            form.setFields([
              {
                name: 'email',
                errors: [t('User not found')],
              },
            ])
            break
            default:
            notification.open({
              type: 'error',
              message: t('Unexpected error'),
              description: t('An error occurred, please try later.'),
            })
            break
        }
    }
  }, [attemptAuthStatus])

  useEffect(() => {
    dispatch(idleAttemptAuthStatus())

    return () => {
      dispatch(idleAttemptAuthStatus())
      dispatch(idleWebauthnAuthenticateOrRegisterStatus())
    }
  }, [])


  const onFinish = (values: { email: string }) => {
    let locale = "en-GB"
    if (i18n.language === "fr") {
      locale = "fr-FR"
    }
    dispatch(attemptAuth({ ...values, locale }))
  }


  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }


  function onPlatformAuthenticatorEmailChange(e: any) {
    setPlatformAuthenticatorEmail(e && e.target ? e.target.value : '')
  }


  function webauthnAuthenticate(values: { email: string }) {
    dispatch(attemptWebauthnAuthenticate({ username: values.email }))
  }


  function webauthnAuthenticateOrRegister() {
    if (user) {
      dispatch(attemptWebauthnAuthenticateOrRegister({ username: user.email, jwt: user.jwt }))
    }
  }


  return (
    <>
      <div className="auth-page">
        <div className="background-gradient"></div>
        <div className='logo-block'>
          <img className="logo" src="/images/logo.png"/>
          <h3 className="logo-text">Secure & Access</h3>
        </div>

        {user && (
          <>
            <h2 className="connected_email">
              <Trans
                i18nKey="LOG_IN_AS"
                ns="auth" 
                values={{ email: user?.email }}
                components={{ bold: <strong /> }} />
            </h2>
            <div>
              <Button type='link' className='logout-jwt' onClick={() => dispatch(logoutJWT())}>
                <BsArrowLeftShort size="1.4rem" />
                {t('Connect with another account')}
              </Button>
            </div>
          </>
        )}

        <div className="auth-form">
          {!user ? (
            <>
              <div className='auth-form-title-container'>
                <p className='auth-form-title'>{t('I already have a Secure&Access account')}</p>
                <LanguageSwitch/>
              </div>
              
              <Form
                form={form}
                name="login"
                initialValues={{ email: '' }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label={false}
                  name="email"
                  rules={[
                    { required: true, message: t('Please input your email', { ns: "application" }) },
                    { type: 'email', message: t('Email is not valid', { ns: "application" }) },
                  ]}
                >
                  <FloatInput
                    label={t("Email")}
                    name="email"
                    type="text"
                  />
                </Form.Item>

                <Form.Item className="mb-1">
                  <Button type="primary" htmlType="submit" block loading={attemptAuthStatus === "loading"}>
                    {t("Login")}
                  </Button>
                </Form.Item>
              </Form>
            </>
          ) : (
            <>
              {connMethod === ConnectionMethod.PIN && (
                storedPin 
                ? <AuthPin/>
                : (
                  user?.jwtFido2 ? (
                    <>
                      <Alert 
                        className='mb-1'
                        message={t('You cannot define PIN code at the moment.')}
                        type="warning"
                        showIcon={true}
                        icon={<AiFillExclamationCircle size="2em"/>}
                      />
                      <p>{t('You have used biometric authentication. Please use it again to access to Secure&Access.')}</p>
                      <p>{t('If you want to set a PIN code please re-authenticate with your login and password.')}</p>
                      <div className='text-align-center'>
                        <Button type='primary' onClick={() => dispatch(logoutJWT())}>{t('Re-authenticate')}</Button>
                      </div>
                    </>
                  ): (
                    <CreatePin/>
                  )
                )
              )}
              {connMethod === ConnectionMethod.BIOMETRIC && (
                !user
                  ? (
                    <Form
                      form={platformAuthenticatorForm}
                      name="basic"
                      initialValues={{ email: '' }}
                      onFinish={webauthnAuthenticate}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                      <Form.Item
                        label={false}
                        name="email"
                        rules={[
                          { required: true, message: t('Please input your email', { ns: "application" }) },
                          { type: 'email', message: t('Email is not valid', { ns: "application" }) },
                        ]}
                      >
                        <FloatInput
                          label={t("Email")}
                          name="email"
                          type="text"
                          onChange={onPlatformAuthenticatorEmailChange}
                        />
                      </Form.Item>

                      <div className="text-align-center mb-1">
                        {webauthnAuthenticateStatus === "loading" 
                        ? (
                          <Spin size='large'/>
                        ) 
                        : (
                          <Button
                            type="primary"
                            htmlType="submit"
                            shape="circle"
                            className="platform-authenticator-button"
                            icon={<IoMdFingerPrint size="4em" />}
                            disabled={!platformAuthenticatorEmail}
                          />
                        )}
                      </div>
                    </Form>
                  ) : (
                    <div className="text-align-center">
                      <div className="feedback-text mb-1">{t('Tap this button to authenticate with device biometric facilities', { ns: 'application'})}</div>
                      
                      {webauthnAuthenticateOrRegisterStatus === "loading" 
                        ? (
                          <Spin size='large'/>
                        ) 
                        : (
                          <Button
                            type="primary"
                            htmlType="submit"
                            shape="circle"
                            className="platform-authenticator-button"
                            icon={<IoMdFingerPrint size="4em" />}
                            onClick={webauthnAuthenticateOrRegister}
                          />
                        )}
                    </div>
                  )
              )}
            </>
          )}
        </div>
        {!user && (
          <div className='auth-form mt-2'>
            <div className="create-account-link">
              <span className='auth-form-title'>{t("You do not have an account?")}</span>
              <Link to="/sign-up" className='signup'>{t("Sign up")}</Link>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Auth
