import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setJWT } from '../../Auth/redux/authSlice'


class ApiError extends Error {
  constructor(message: string) {
    super(message || "unknown_error")
    this.name = "ApiError"
  }
}


interface State {
  attemptSendAccountValidationStatus: string,
  attemptSendAccountValidationError?: string,
  attemptValidateAccountStatus: string,
  attemptValidateAccountError?: string,
}


const initialState:State = {
  attemptSendAccountValidationStatus: "idle",
  attemptSendAccountValidationError: "idle",
  attemptValidateAccountStatus: "idle",
  attemptValidateAccountError: "idle",
}


/**
 * Send validation mail after settting connexion methods
 */
export const attemptSendAccountValidation = createAsyncThunk(
  'accountValidate/attemptSendAccountValidation',
  async (payload: { locale: string }, { getState }) => {

    const { auth } = getState() as { auth: { user: { jwt: string } } }

    const response = await fetch(process.env.REACT_APP_FIDO_BASE_URL + '/user/send-account-validation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth.user.jwt}`,
      },
      body: JSON.stringify(payload),
    })


    if (!response.ok) {
      const reason = (await response.json()).errorMessage || "unknown_error"
      throw new ApiError(reason)
    }
  }
)

export const attemptValidateAccount = createAsyncThunk(
  'accountValidate/attemptValidateAccount',
  async ({ token }: { token: string }, { dispatch }) => {

    const response = await fetch(process.env.REACT_APP_FIDO_BASE_URL + '/user/validate-account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }

    const body = await response.json()

    dispatch(setJWT(body.jwt))
  }
)



const accoutValidateSlice = createSlice({
  name: 'accountValidate',
  initialState,
  reducers: {
    idleAttemptSendAccountValidationStatus: (state) => {
      state.attemptSendAccountValidationStatus = "idle"
      state.attemptSendAccountValidationError = undefined
    },
    idleAttemptValidateAccountStatus: (state) => {
      state.attemptValidateAccountStatus = "idle"
      state.attemptValidateAccountError = undefined
    },
  },

  extraReducers: {

    [attemptSendAccountValidation.pending.type]: (state, action) => {
      state.attemptSendAccountValidationStatus = "loading"
      state.attemptSendAccountValidationError = undefined
    },
    [attemptSendAccountValidation.fulfilled.type]: (state, action) => {
      state.attemptSendAccountValidationStatus = "success"
      state.attemptSendAccountValidationError = undefined
    },
    [attemptSendAccountValidation.rejected.type]: (state, action) => {
      const error = action.error
      state.attemptSendAccountValidationError = error.name === "ApiError" ? error.message : "unknown_error"
    },

    [attemptValidateAccount.pending.type]: (state, action) => {
      state.attemptValidateAccountStatus = "loading"
      state.attemptValidateAccountError = undefined
    },
    [attemptValidateAccount.fulfilled.type]: (state, action) => {
      state.attemptValidateAccountStatus = "success"
      state.attemptValidateAccountError = undefined
    },
    [attemptValidateAccount.rejected.type]: (state, action) => {
      const error = action.error
      state.attemptValidateAccountError = error.name === "ApiError" ? error.message : "unknown_error"
    },
  },
  
})


export default accoutValidateSlice.reducer


export const {
  idleAttemptSendAccountValidationStatus,
  idleAttemptValidateAccountStatus,
} = accoutValidateSlice.actions
