import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, notification } from 'antd'
import { AppDefinition, AppActionDefinition, APPLICATIONS } from '../../Applications/apps'
import { AppDispatch, RootState } from '../../../store'
import EmailField from '../../Applications/fields/EmailField'
import { useTranslation } from 'react-i18next'
import {
  attemptUpdateApplicationData,
  idleAttemptUpdateApplicationDataStatus,
} from '../../Applications/redux/applicationsSlice'
import './ShareDataComponent.scss'
import { setFieldEmail } from '../../Applications/fields/redux/fieldsSlice'


interface Props {
  app: string
  appDefinition: AppDefinition
  action: string
  actionDefinition: AppActionDefinition
  accept: (values: any) => void
  decline: () => void
  shareMessage?: React.ReactElement
  actionsElement?: React.ReactElement
}


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}


/**
 * @param props {Props}
 * @returns 
 */
function ShareDataComponent({ app, appDefinition, actionDefinition, accept, decline, shareMessage, actionsElement } : Props) {
  const dispatch:AppDispatch = useDispatch()
  const { t: tApplication } = useTranslation('application')
  const { t: tShareData } = useTranslation('shareData')
  
  const application = APPLICATIONS[app || 'NONE']

  const [form] = Form.useForm()
  const acceptShareDataStatus = useSelector((state:RootState) => state.shareData.acceptShareDataStatus)
  const attemptGetApplicationDataStatus = useSelector((state:RootState) => state.applications.attemptGetApplicationDataStatus)
  const attemptUpdateApplicationDataStatus = useSelector((state:RootState) => state.applications.attemptUpdateApplicationDataStatus)
  const appData = useSelector((state:RootState) => state.applications.data ? state.applications.data[app || ''] : '')
  let appParsedData:{[field:string]: string}|undefined
  try {
    appParsedData = JSON.parse(appData)
  } catch(e) {}

  useEffect(() => {
    if (appParsedData) {
      form.setFieldsValue({
        email: appParsedData['email'],
      })
      dispatch(setFieldEmail(appParsedData['email']))
    }
  }, [appParsedData])


  useEffect(() => {
    switch(attemptUpdateApplicationDataStatus) {
      case 'success':
        notification.open({
          type: 'success',
          message: tApplication('Success'),
          description: tApplication('Your application data has been successfully persisted')
        })
        accept(form.getFieldsValue())
        break

      case 'error':
        notification.open({
          type: 'error',
          message: tApplication('Unexpected error'),
          description: tApplication('Error persisting your application data')
        })
        break
    }

    return () => {
      dispatch(idleAttemptUpdateApplicationDataStatus())
    }
  }, [attemptUpdateApplicationDataStatus])


  const onFinish = (values: any) => {
    // Save data
    dispatch(attemptUpdateApplicationData({
      name: app || '',
      data: JSON.stringify(values),
    }))
  }


  return (
    <>
      <div className="share_data_title">
        <div className="application-image"><img src={appDefinition.icon}/></div>
        {appDefinition.name}
      </div>
      {/* <div className="share_data_action">{actionDefinition.name}</div> */}

      {shareMessage && <div className="mt-1">{shareMessage}</div>}

      <Form
        name="application"
        {...formItemLayout}
        onFinish={onFinish}
        form={form}
      >
        {application.fields.includes('email') && <EmailField validationVisible={false}/>}

        {actionsElement
          ? actionsElement
          : <div className="share_data_actions">
              <Button danger onClick={decline}>{tShareData('Cancel')}</Button>
              <Button type="primary" htmlType="submit"
                loading={acceptShareDataStatus === "loading" || attemptGetApplicationDataStatus === "loading" }
              >
                {tShareData('Accept')}
              </Button>
            </div>
        }
      </Form>
    </>
  )
}

export default ShareDataComponent
