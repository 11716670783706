import React from 'react'
import {  useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useTranslation } from 'react-i18next'
import './set-auth-methods.scss'
import AuthMehods from '../../components/AuthMethods/AuthMethods'

interface Props {
  from: "signup" | "auth"
}

function SetAuthMethods({ from }: Props) {
  const { t } = useTranslation('setAuthMethods')
  const app = useSelector((state: RootState) => state.shareData.app)

  return (
    <>
      <div className="sign_auth_methods-finalize-page">
        <div className="background-gradient"></div>
        <div className='logo-block'>
          <img className="logo" src="/images/logo.png"/>
          <h3 className="logo-text">Secure & Access</h3>
        </div>
        {from === "signup" ? (
          <AuthMehods from={from} title={t("Account creation", { ns: "application" })} subtitle={app ? " (2 / 3)" : " (2 / 2)"}/>
        ) : 
        (
          <AuthMehods from={from} title={t("Password setup")}/>
        )}
      </div>
    </>
  )
}

export default SetAuthMethods
