import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ApiError from '../../../errors/ApiError'
import { FetchStatus } from '../../../utils/FetchStatus'


interface AppData {
  [name: string]: string
}


interface ValidatedData {
  [email: string]: string
}


interface State {
  attemptGetApplicationDataStatus: FetchStatus
  attemptUpdateApplicationDataStatus: FetchStatus
  data: AppData
  validatedEmails: ValidatedData
}


const initialState:State = {
  attemptGetApplicationDataStatus: "idle",
  attemptUpdateApplicationDataStatus: "idle",
  data: { },
  validatedEmails: { },
}


/**
 * Auth user with email and password.
 */
export const attemptGetApplicationData = createAsyncThunk(
  'auth/attemptGetApplicationData',
  async (payload, { getState }) => {

    const { auth } = getState() as { auth: { user: { jwt: string } } }

    const response = await fetch(process.env.REACT_APP_RELAY_BASE_URL + '/application/get-data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth.user.jwt}`,
      },
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }

    const json = await response.json()

    return {
      data: json['data'],
      validatedEmails: json['validatedEmails'],
    }
  }
)


/**
 * Auth user with email and password.
 */
export const attemptUpdateApplicationData = createAsyncThunk(
  'auth/attemptUpdateApplicationData',
  async (payload: { name: string, data: string }, { getState }) => {

    const { auth } = getState() as { auth: { user: { jwt: string } } }

    const response = await fetch(process.env.REACT_APP_RELAY_BASE_URL + '/application/update-data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth.user.jwt}`,
      },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }

    const json = await response.json()

    return {
      application: payload.name,
      data: json['data'],
      validatedEmails: json['validatedEmails'],
    }
  }
)


const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    idleAttemptGetApplicationDataStatus: (state) => {
      state.attemptGetApplicationDataStatus = "idle"
    },
    idleAttemptUpdateApplicationDataStatus: (state) => {
      state.attemptUpdateApplicationDataStatus = "idle"
    },
  },

  extraReducers: {
    [attemptGetApplicationData.pending.type]: (state, action) => {
      state.attemptGetApplicationDataStatus = "loading"
    },

    [attemptGetApplicationData.fulfilled.type]: (state, action: { payload: { validatedEmails: any, data: {name: string, data: string}[] }}) => {
      state.attemptGetApplicationDataStatus = "success"
      state.validatedEmails = action.payload.validatedEmails.reduce(
        (result: ValidatedData, current: { email: string, status: string}) => {
          return {...result, ...{[current.email]: current.status}}
        },
        {}
      )

      state.data = action.payload.data.reduce(
        (result: AppData, { name, data }) => {
          return { ...result, ...{ [name]: data } }
        },
        {}
      )
    },

    [attemptGetApplicationData.rejected.type]: (state, action) => {
      const error = action.error
      state.attemptGetApplicationDataStatus = "error"
    },

    [attemptUpdateApplicationData.pending.type]: (state, action) => {
      state.attemptUpdateApplicationDataStatus = "loading"
    },

    [attemptUpdateApplicationData.fulfilled.type]: (state, action: { payload: { validatedEmails: any, data: string, application: string }}) => {
      state.attemptUpdateApplicationDataStatus = "success"
      if (!state.data) {
        state.data = {}
      }
      state.data[action.payload.application] = action.payload.data

      state.validatedEmails = action.payload.validatedEmails.reduce(
        (result: ValidatedData, current: { email: string, status: string}) => {
          return {...result, ...{[current.email]: current.status}}
        },
        {}
      )
    },

    [attemptUpdateApplicationData.rejected.type]: (state, action) => {
      const error = action.error
      state.attemptUpdateApplicationDataStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },
  },
  
})


export default applicationsSlice.reducer


export const {
  idleAttemptGetApplicationDataStatus,
  idleAttemptUpdateApplicationDataStatus,
} = applicationsSlice.actions
