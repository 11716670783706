import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { setJWT } from '../screens/Auth/redux/authSlice'
import { getRegChallenge, createCredential, ServerPublicKeyCredentialCreationOptionsRequest, getAuthChallenge, getAssertion } from './webAuthnUtils'


class ApiError extends Error {
  constructor(message: string) {
    super(message || "unknown_error")
    this.name = "ApiError"
  }
}


interface State {
  webauthnRegisterStatus: string,
  webauthnAuthenticateStatus: string,
  webauthnAuthenticateOrRegisterStatus: string,
}


const initialState:State = {
  webauthnRegisterStatus: "idle",
  webauthnAuthenticateStatus: "idle",
  webauthnAuthenticateOrRegisterStatus: "idle",
}


/**
 * Register user with email and webauthn.
 */
export const attemptRegister = createAsyncThunk(
  'webAuthn/attemptRegister',
  async ({ username, jwt }: { username: string, jwt?: string }, { dispatch }) => {
    const displayName = username

    // prepare parameter
    const serverPublicKeyCredentialCreationOptionsRequest: ServerPublicKeyCredentialCreationOptionsRequest = {
      username,
      jwt,
      displayName,
      authenticatorSelection: {
        requireResidentKey: false,
        userVerification: "discouraged" /*"required"*/,
        authenticatorAttachment: process.env.REACT_APP_WEBAUTHN_AUTHENTICATOR_ATTACHMENT || "platform",
      },
      attestation: "none",
    }

    const createCredentialOptions = await getRegChallenge(serverPublicKeyCredentialCreationOptionsRequest)

    try {
      const credentialResponse = await createCredential(createCredentialOptions)
      if (credentialResponse.jwt) {
        dispatch(setJWT(credentialResponse.jwt))
      }
    } catch(e) {
      console.error(e)
      throw e
    }
    
  }
)


/**
 * Auth user with email and webauthn.
 */
export const attemptWebauthnAuthenticate = createAsyncThunk(
  'webAuthn/attemptAuthenticate',
  async ({ username }: { username: string }, { dispatch }) => {
    // prepare parameter
    const serverPublicKeyCredentialGetOptionsRequest = {
      username, 
      userVerification: "required",
    }

    const getCredentialOptions = await getAuthChallenge(serverPublicKeyCredentialGetOptionsRequest)
    const assertionResponse = await getAssertion(getCredentialOptions)
    if (assertionResponse.jwt) {
      dispatch(setJWT(assertionResponse.jwt))
    }
  }
)


export const attemptWebauthnAuthenticateOrRegister = createAsyncThunk(
  'webAuthn/attemptWebauthnAuthenticateOrRegister',
  async ({ username, jwt }: { username: string, jwt: string }, { dispatch }) => {
    // prepare parameter
    const serverPublicKeyCredentialGetOptionsRequest = {
      username,
      userVerification: "required",
    }

    try {
      const getCredentialOptions = await getAuthChallenge(serverPublicKeyCredentialGetOptionsRequest)
      const assertionResponse = await getAssertion(getCredentialOptions)
      if (assertionResponse.jwt) {
        dispatch(setJWT(assertionResponse.jwt))
      }
    }
    catch (e: any) {
      try {
        const parsed = JSON.parse(e)
        if (parsed.serverResponse && parsed.serverResponse.internalError == "CREDENTIAL_NOT_FOUND") {
          dispatch(attemptRegister({ username, jwt }))
        }
      }
      catch (e) {
        throw e
      }
    }

  }
)


const webAuthnSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    idleWebauthnRegisterStatus: (state) => {
      state.webauthnRegisterStatus = "idle"
    },
    idleWebauthnAuthenticateStatus: (state) => {
      state.webauthnAuthenticateStatus = "idle"
    },
    idleWebauthnAuthenticateOrRegisterStatus: (state) => {
      state.webauthnAuthenticateOrRegisterStatus = "idle"
    },
  },

  extraReducers: {
    [attemptRegister.pending.type]: (state, action) => {
      state.webauthnRegisterStatus = "loading"
    },
    [attemptRegister.fulfilled.type]: (state, action) => {
      state.webauthnRegisterStatus = "success"
    },
    [attemptRegister.rejected.type]: (state, action) => {
      const error = action.error
      state.webauthnRegisterStatus = error.message || "unknown_error"
    },

    [attemptWebauthnAuthenticate.pending.type]: (state, action) => {
      state.webauthnAuthenticateStatus = "loading"
    },
    [attemptWebauthnAuthenticate.fulfilled.type]: (state, action) => {
      state.webauthnAuthenticateStatus = "success"
    },
    [attemptWebauthnAuthenticate.rejected.type]: (state, action) => {
      const error = action.error
      state.webauthnAuthenticateStatus = error.message || "unknown_error"
    },

    [attemptWebauthnAuthenticateOrRegister.pending.type]: (state, action) => {
      state.webauthnAuthenticateOrRegisterStatus = "loading"
    },
    [attemptWebauthnAuthenticateOrRegister.fulfilled.type]: (state, action) => {
      state.webauthnAuthenticateOrRegisterStatus = "success"
    },
    [attemptWebauthnAuthenticateOrRegister.rejected.type]: (state, action) => {
      const error = action.error
      state.webauthnAuthenticateOrRegisterStatus = error.message || "unknown_error"
    },
  },
  
})


export default webAuthnSlice.reducer


export const {
  idleWebauthnRegisterStatus,
  idleWebauthnAuthenticateStatus,
  idleWebauthnAuthenticateOrRegisterStatus,
} = webAuthnSlice.actions
