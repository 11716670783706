import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist-indexeddb-storage'
import appReducer from './appSlice'
import authReducer from './screens/Auth/redux/authSlice'
import webAuthnReducer from './webauthn/webAuthnSlice'
import applicationsReducer from './screens/Applications/redux/applicationsSlice'
import fieldsReducer from './screens/Applications/fields/redux/fieldsSlice'
import validationReducer from './screens/Validation/redux/validationSlice'
import shareDataReducer from './screens/ShareData/redux/shareDataSlice'
import reinitPasswordReducer from './screens/ReinitPassword/redux/reinitPasswordSlice'
import accountValidateReducer from './screens/AccountValidate/redux/accountValidateSlice'

const persistConfig = {
  key: 'root',
  storage: storage('saDB'),
  blacklist: ['app', 'webAuthn', 'applications', 'fields', 'validation', 'shareData', 'reinitPassword', 'accountValidate'],
}

const reducers = combineReducers({
  app: appReducer,
  auth: authReducer,
  webAuthn: webAuthnReducer,
  applications: applicationsReducer,
  fields: fieldsReducer,
  validation: validationReducer,
  shareData: shareDataReducer,
  reinitPassword: reinitPasswordReducer,
  accountValidate: accountValidateReducer,
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"]
      }
    }),
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
