import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Form, Button, notification, Spin, Alert } from 'antd'
import { AppDispatch, RootState } from '../../store'
import { useTranslation } from 'react-i18next'
import { IoMdFingerPrint, IoMdKeypad } from 'react-icons/io'
import './auth-methods.scss'
import { attemptWebauthnAuthenticateOrRegister, attemptWebauthnAuthenticate, idleWebauthnAuthenticateStatus, idleWebauthnAuthenticateOrRegisterStatus } from '../../webauthn/webAuthnSlice'
import FloatInput from '../../components/FloatInput'
import { AiFillExclamationCircle } from 'react-icons/ai'
import classNames from 'classnames'
import { LanguageSwitch } from '../../screens/LanguageSwitch/LanguageSwitch'
import AuthPin from '../../screens/AuthCreateSecondFactor/AuthPin/AuthPin'
import CreatePin from '../../screens/AuthCreateSecondFactor/CreatePin/CreatePin'
import { attemptSendAccountValidation } from '../../screens/AccountValidate/redux/accountValidateSlice'
import { logoutJWT, selectPIN } from '../../screens/Auth/redux/authSlice'
import { ConnectionMethod } from './ConnectionMethod'

interface Props {
  title: string
  subtitle?: string
  from: string
}

function AuthMehods({title, subtitle, from} : Props) {
  const [platformAuthenticatorForm] = Form.useForm()
  const dispatch:AppDispatch = useDispatch()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation('setAuthMethods')
  const user = useSelector((state:RootState) => state.auth.user)
  const webauthnAuthenticateStatus = useSelector((state:RootState) => state.webAuthn.webauthnAuthenticateStatus)
  const webauthnAuthenticateOrRegisterStatus = useSelector((state:RootState) => state.webAuthn.webauthnAuthenticateOrRegisterStatus)
  const [platformAuthenticatorAvailable, setPlatformAuthenticatorAvailable] = useState(false)
  const [platformAuthenticatorEmail, setPlatformAuthenticatorEmail] = useState('')
  const [connMethod, setConnMethod] = useState<ConnectionMethod | undefined>()
  const storedPin = useSelector(selectPIN)


  /* Disable biometric until iPhone bug is resolved.
  useEffect(() => {
    checkPlatformAuthenticatorAvailable()
      .then((available) => {
        setPlatformAuthenticatorAvailable(available)
        if (available && !storedPin) {
          setTabActiveKey("biometric")
        }
      })
  }, [])
  */

  useEffect(() => {
    if (user && user.secondFactor) {
      navigate('/?from=signup')
    }
  }, [user])

  useEffect(() => {
    switch (webauthnAuthenticateStatus) {
      case "unknown_error":
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('An error occurred, please try later.'),
        })
        break
    }

    return () => {
      dispatch(idleWebauthnAuthenticateStatus())
    }
  }, [webauthnAuthenticateStatus])


  useEffect(() => {
    return () => {
      if (from === "signup") {
        let locale = "en-GB"
        if (i18n.language === "fr") {
          locale = "fr-FR"
        }

        dispatch(attemptSendAccountValidation({ locale }))
      }
      dispatch(idleWebauthnAuthenticateOrRegisterStatus())
    }
  }, [])


  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }


  function onPlatformAuthenticatorEmailChange(e: any) {
    setPlatformAuthenticatorEmail(e && e.target ? e.target.value : '')
  }


  function webauthnAuthenticate(values: { email: string }) {
    dispatch(attemptWebauthnAuthenticate({ username: values.email }))
  }


  function webauthnAuthenticateOrRegister() {
    if (user) {
      dispatch(attemptWebauthnAuthenticateOrRegister({ username: user.email, jwt: user.jwt }))
    }
  }


  function setConnectionMethod(method: ConnectionMethod) {
    if (method === "biometric" && !platformAuthenticatorAvailable) {
      return
    }

    setConnMethod(method)
  }


  return (
    <>
      <div className='signup-header-container'>
        <h2 className="signup-header-title">{title}
          <span className="signup-header-step">{subtitle}</span>
        </h2>
      </div>
      {!connMethod ? (
        <div className='connection-method-form'>
          <span className='language'>
            <LanguageSwitch/>
          </span>
          <h3 className='connection-method-title'>{t("Choose your quick connection method")}</h3>
    
          <div className='connection-method-container'>
            <div className='connection-method' onClick={() => setConnectionMethod(ConnectionMethod.PIN)}>
              <IoMdKeypad size="3em" />
              <h2>{t('PIN')}</h2>
            </div>
            <span className={classNames({
              "connection-method": true,
              "connection-method--disabled": !platformAuthenticatorAvailable,
              })}
              onClick={() => setConnectionMethod(ConnectionMethod.BIOMETRIC)}
            >
              <IoMdFingerPrint size="3em" />
              <h2>{t('Biometric', { ns: "application" })}</h2>
            </span>
          </div>
        </div>
      ) : (
        <div className="auth-form">
          {connMethod == ConnectionMethod.PIN
            && (storedPin 
                ? <AuthPin/>
                : (
                  user?.jwtFido2 ? (
                    <>
                      <Alert 
                        className='mb-1'
                        message={t('You cannot define PIN code at the moment.')}
                        type="warning"
                        showIcon={true}
                        icon={<AiFillExclamationCircle size="2em"/>}
                      />
                      <p>{t('You have used biometric authentication. Please use it again to access to Secure&Access.')}</p>
                      <p>{t('If you want to set a PIN code please re-authenticate with your login and password.')}</p>
                      <div className='text-align-center'>
                        <Button type='primary' onClick={() => dispatch(logoutJWT())}>{t('Re-authenticate')}</Button>
                      </div>
                    </>
                  ): (
                    <CreatePin/>
                  )
                )
            )
          }

          {connMethod == ConnectionMethod.BIOMETRIC
            && (
              !user
                ? (
                  <Form
                    form={platformAuthenticatorForm}
                    name="basic"
                    initialValues={{ email: '' }}
                    onFinish={webauthnAuthenticate}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Form.Item
                      label={false}
                      name="email"
                      rules={[
                        { required: true, message: t('Please input your email', { ns: "application" }) },
                        { type: 'email', message: t('Email is not valid', { ns: "application" }) },
                      ]}
                    >
                      <FloatInput
                        label={t("Email")}
                        name="email"
                        type="text"
                        onChange={onPlatformAuthenticatorEmailChange}
                      />
                    </Form.Item>

                    <div className="text-align-center mb-1">
                      {webauthnAuthenticateStatus === "loading" 
                      ? (
                        <Spin size='large'/>
                      ) 
                      : (
                        <Button
                          type="primary"
                          htmlType="submit"
                          shape="circle"
                          className="platform-authenticator-button"
                          icon={<IoMdFingerPrint size="4em" />}
                          disabled={!platformAuthenticatorEmail}
                        />
                      )}
                    </div>
                  </Form>
                ) : (
                  <div className="text-align-center">
                    <div className="feedback-text mb-1">{t('Tap this button to authenticate with device biometric facilities', { ns: 'application'})}</div>
                    
                    {webauthnAuthenticateOrRegisterStatus === "loading" 
                      ? (
                        <Spin size='large'/>
                      ) 
                      : (
                        <Button
                          type="primary"
                          htmlType="submit"
                          shape="circle"
                          className="platform-authenticator-button"
                          icon={<IoMdFingerPrint size="4em" />}
                          onClick={webauthnAuthenticateOrRegister}
                        />
                      )}
                  </div>
                )
            )
          }
        </div>  
      )}
    </>
  )
}

export default AuthMehods
